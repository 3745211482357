<template>
  <v-expansion-panels flat :data-cy="props.useJsonLD ? $testids.FAQ_PANEL.PANEL : $testids.L_EXPANSION_PANEL.EXPANSION_PANEL">
    <v-expansion-panel
        v-for="(item,i) in items"
        :key="i"
        :eager="true"
        flat
        :data-cy="props.useJsonLD ? $testids.FAQ_PANEL.ITEM : $testids.L_EXPANSION_PANEL.EXPANSION_PANEL_ITEM">
      <v-expansion-panel-title>
        <component :is="h3Title ? 'h3' : 'div'" class="body-1 !font-medium text-left" :data-cy="props.useJsonLD ? $testids.FAQ_PANEL.QUESTION : $testids.L_EXPANSION_PANEL.EXPANSION_PANEL_TITLE">
          {{ item[itemTitle] }}
        </component>
      </v-expansion-panel-title>
      <v-expansion-panel-text :eager="true">
          <div v-html="item[itemText]" :data-cy="props.useJsonLD ? $testids.FAQ_PANEL.ANSWER : $testids.L_EXPANSION_PANEL.EXPANSION_PANEL_TEXT"></div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script lang="ts" setup>

import {
  type ExpansionPanelElement,
  type FaqElement,
  useFAQ
} from '~/service/jsonLd/FAQ/useFAQ';

const props = withDefaults(defineProps<{
  items: FaqElement[] | ExpansionPanelElement[]
  useJsonLD?: boolean
  itemTitle?: string
  itemText?: string
  titleTag?: string;
}>(), {
  useJsonLD: false,
  itemTitle: 'title',
  itemText: 'text',
  titleTag: 'div',
});
const { createFaqSchema, } = useFAQ();

function isFaqInstance(value: any): value is FaqElement[] {
    for (const item of value) {
      if ('question' in item) {
        return true;
      }
    }
}

if(props.useJsonLD && isFaqInstance(props.items)) {
  useJsonld(createFaqSchema(props.items));
}

</script>
<style scoped>
</style>


